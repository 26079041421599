<template>
  <b-card class="mb-4 border-0">
    <template #header>
      <strong>SKU distribution configurations</strong>
      <div class="info-button-wrapper float-right">
        <div id="info-default-configs" v-b-hover="handleInfoHover">
          <b-icon v-if="isHovered" icon="info-circle-fill" variant="info" scale="2"></b-icon>
          <b-icon v-else icon="info-circle" scale="2"></b-icon>
        </div>
        <b-tooltip
          id="info-default-configs-tooltip"
          target="info-default-configs"
          triggers="hover"
          placement="left"
          custom-class="dark-tooltip">
          Distribution % of each SKU represents the probability of it being chosen when a new order is created using that product type
        </b-tooltip>
      </div>
    </template>

    <h6>Product type: {{productType.productTypeName}}</h6>
    <b-row class="pl-3">
    <b-spinner v-if="loading"></b-spinner>
    </b-row>
    <b-row v-for="(option,index) in skuDistributionOptions" :key="index">
      <b-row class="d-flex justify-content-center p-3">
        <b-col>
          <strong>SKU</strong>
          <b-form-select :id="'sku-input'+index"
              v-model="option.sku"
              :options="skuOptionsFiltered">
          </b-form-select>
          <small>Select SKU</small>
          <div v-if="shouldShowSupportedTherapies(option)">
            <b-icon icon="info-circle-fill" variant="info" scale="1"></b-icon>
            <span style="color: rgb(250, 99, 99);"> Supported Therapies: {{ getSupportedTherapies(option) }}</span>
          </div>
        </b-col>

        <b-col>
          <strong>Distribution</strong>
          <b-form-input :id="'distribution-input'+index"
            type="number"
            :value="option.distribution"
            v-model="option.distribution"
            min="1"
            max="100">
          </b-form-input>
          <small>Insert distribution %</small>
        </b-col>

        <b-button :id="'delete-sku-button-'+index"
                  v-b-tooltip.hover
                  title="Delete sku"
                  size="sm"
                  variant="danger"
                  style="min-width: 0px; height: 40px; margin-top: 1.5rem;"
                  @click="deleteSkuDistribution(option)">
          <feather type="trash-2"></feather>
        </b-button>
      </b-row>
    </b-row>
    <b-button variant="primary" id="add-sku-button" class="mt-3 mb-3"
    @click="addSkuDistribution()" style="min-width: 0px; height: 40px;">Add +</b-button>

    <b-col cols="3" class="col_buttons mt-3">
      <b-button variant="light" class="mr-3" to="/skuconfigs">Cancel</b-button>
      <b-button variant="primary" @click="sendSKUDistributions">Confirm</b-button>
    </b-col>
  </b-card>
</template>

<script>
import axios from 'axios';
import { PRODUCT_TYPE } from '@/scripts/constants';

export default {
  name: 'edit-sku-configurations',
  async beforeMount() {
    this.productTypeId = this.$route.params.productType;
    await this.loadProductTypes();
  },
  async mounted() {
    await this.loadSkuDistributions();
  },
  data() {
    return {
      loading: false,
      productType: {},
      skuDistributionOptions: [],
      skuOptionsFiltered: [],
      isHovered: false,
    };
  },
  methods: {
    getSupportedTherapies(option) {
      if (!this.shouldShowSupportedTherapies(option)) {
        return '';
      }
      const therapies = this.skuOptionsFiltered.filter(sku => sku.value === option.sku)[0]?.therapies;

      return therapies?.map(therapy => therapy.label).join(', ');
    },
    shouldShowSupportedTherapies(option) {
      return option.sku !== null && option.sku !== '' && this.productType.productTypeKey === PRODUCT_TYPE.ComputerVision;
    },
    addSkuDistribution() {
      this.skuDistributionOptions.push({ id: Math.random(), sku: '', distribution: 1 });
    },
    async deleteSkuDistribution(skuDistribution) {
      this.skuDistributionOptions = this.skuDistributionOptions.filter(sku => sku.id !== skuDistribution.id);
    },
    validateSkuDistributions() {
      const skuOptions = [];
      const distributionParsed = this.skuDistributionOptions.map(option => ({
        id: option.id,
        sku: option.sku,
        distribution: parseFloat(option.distribution),
      }));

      const zeroValuedDistributions = distributionParsed.some(d => d.distribution === 0);
      if (zeroValuedDistributions) {
        this.$noty.error('Distributions cannot be configured with a value of zero');
        return [];
      }

      const distributionOverall = distributionParsed
        .reduce((skuDistributionSum, option) => skuOptions.push(option.sku) && skuDistributionSum + option.distribution, 0);

      const uniqueSkus = [ ...new Set(skuOptions) ];

      if (uniqueSkus.length !== this.skuDistributionOptions.length) {
        this.$noty.error('Can not have more than one distribution % configuration for the same sku');
        return [];
      }

      if (distributionOverall !== 100) {
        this.$noty.error('To save distribution % the sum of all SKU distributions must be 100%');
        return [];
      }

      return distributionParsed;
    },
    async sendSKUDistributions() {
      const validSkuDistribution = this.validateSkuDistributions();

      if (!validSkuDistribution.length) return;

      const isConfirm = await this.$bvModal.msgBoxConfirm('Are you sure you want to save this SKU distribution?');
      if (!isConfirm) {
        return;
      }
      await axios
        .post(`v1/skus/${this.productTypeId}/default-distributions/`, {
          product_type_sku: validSkuDistribution,
        })
        .then(() => {
          this.$noty.success('SKU distribution successfully saved');
          this.$router.go(-1);
        })
        .catch(() => {
          this.$noty.error('Unable to save sku distributions');
        });
    },
    async loadSkuOptions() {
      const params = {
        offset: 0,
        limit: 100,
        product_type: this.productType.productTypeKey,
        sort_order: 'asc',
        sort_by: 'name',
      };

      const response = await this.$store.dispatch('OrderManagement/getSkus', params);

      this.loading = false;

      if (!response) {
        return;
      }

      const { skus } = response;

      skus.forEach(p => {
        this.skuOptionsFiltered.push({
          product_type: p.product_type,
          text: `${p.name} (${p.stock})`,
          value: p.name,
          therapies: p.therapies,
        });
      });
    },
    async loadProductTypes() {
      this.loading = true;
      await axios
        .get('v1/skus/defaults')
        .then(response => {
          this.productType = response.data.find(productTypeResponse => (
            productTypeResponse.ID === parseInt(this.productTypeId, 10)
          ));
          this.loadSkuOptions();
        })
        .catch(() => {
          this.$noty.error('Unable to load product types');
          this.loading = false;
        });
    },
    async loadSkuDistributions() {
      this.loading = true;
      await axios
        .get(`v1/skus/${this.productTypeId}/default-distributions/`)
        .then(response => {
          this.skuDistributionOptions = response.data.product_type_sku;
        })
        .catch(() => {
          this.$noty.error('Unable to load SKU distributions');
        }).finally(() => { this.loading = false; });
    },
    handleInfoHover(hovered) {
      this.isHovered = hovered;
    },
  },
};
</script>
